<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Notification from "@/services/Notification";
import _ from "underscore";


/**
 * Starter page
 */
export default {
    components: { Layout, PageHeader, countTo },
    page: {
        title: "Notification Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    async created() {
        this.getNotificationByGroup(this.$route.query.id)
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.currentPage = 1;
                this.getNotificationRecipients(this.$route.query.id)
            }, 1500),
            deep: true
        },
    },
    methods: {

        getFilters(){
            var filter_string = '';

            return filter_string = filter_string.substring(1);
        },
        loadFullTable() {
            this.showLoader_c1 = true;
            this.getNotificationRecipients(this.$route.query.id)
                .then(() => {
                    this.showLoader_c1 = false;
                    this.showFullTable = true;
                })
                .catch((error) => {
                    console.error('Error loading full table:', error);
                    this.showLoader_c1 = false;
                });
        },


        getNotificationByGroup(id){
            Notification.getNotificationByGroup(id)
            .then(response => {
                this.notifInfo = response.data.notification;
                this.stats = response.data.stats;
                this.totalItems = this.stats.processed.IOS + this.stats.processed.Android;
//                this.getNotificationRecipients(this.$route.query.id);
            })
            .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },

        getNotificationRecipients(id) {
            this.showLoader_c1 = true;
            var filters1 = this.getFilters()
            let filters = `id=${id}&totalItems=${this.totalItems}`+filters1;
            let paginationFilters = this.setPaginationFilters(filters)
            return Notification.getNotificationRecipients(paginationFilters)
                .then(response => {
                    this.notifRecipient = response.data.data;
                    this.totalItems = this.stats.processed.IOS + this.stats.processed.Android;
                    this.totalPages = Math.ceil(this.totalItems / this.perPage);
                })
                .catch(error => {
                    console.error('Error loading notification recipients:', error);
                    throw error;
                })
                .finally(() => {
                    this.showLoader_c1 = false;
                });
        },

        formatToken(token) {
            if (!token) return '';
            const start = token.substring(0, 7);
            const end = token.substring(token.length - 7);
            return `${start}...${end}`;
        },
        copyToken(token) {
            navigator.clipboard.writeText(token)
                .then(() => {
                    console.log('Token copied to clipboard');
                })
                .catch(err => {
                    console.error('Error copying token to clipboard: ', err);
                });
        },

        handlePaginationChange(){
            this.getNotificationRecipients(this.$route.query.id)
        },
    },
    data() {
        return {
            title: "Notification Details",
            items: [
                {
                    text: "Notifications",
                    href: "/notifications",
                },
                {
                    text: this.$route.query.id,
                    active: true,
                },
            ],
            notifInfo: {

            },

            stats: {
                processed: {
                    IOS: 0,
                    Android: 0
                },
                success: {
                    IOS: 0,
                    Android: 0
                },
                error: {
                    IOS: 0,
                    Android: 0
                },
            },
            filter: "",
            totalPages: 0,
            totalItems: 0,
            currentPage: 1,
            perPage: 10,
            showLoader_c1:false,
            showLoader: false,
            showFullTable: false,
            itemsPerPageOptions: [10, 25, 50, 100],
            tableHeaders:[
                { text: 'User', value: 'user' },
                { text: 'Email', value: 'email' },
                { text: 'Token', value: 'token' },
                { text: 'Device Platform', value: 'device_platform' },
                { text: 'Success', value: 'success' },
                { text: 'Error', value: 'error' },
            ],
            notifRecipient: [],
        };
    },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-primary"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-primary">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.processed.IOS + stats.processed.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-primary" style="font-size: x-large;">Notification Processed</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.processed.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.processed.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-success"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-success">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.success.IOS + stats.success.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-success" style="font-size: x-large;">Notification Accepted</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.success.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.success.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-danger"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-danger">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.error.IOS + stats.error.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-danger" style="font-size: x-large;">Notification Rejected</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.error.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.error.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
<!-- end row-->

    <div class="row">
        <div class="col-md-6 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title center">Notification Data</h4>
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Message</th>
                                    <th>Recipient</th>
                                    <th>Type</th>
                                    <th>Language</th>
                                    <th>Set Up / Scheduled	</th>
                                    <th>Initiated</th>
                                    <th>Completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{{notifInfo.notification_id}}</th>
                                    <td>{{notifInfo.title}}</td>
                                    <td>{{notifInfo.message}}</td>
                                    <td>{{notifInfo.recipient}}</td>
                                    <td>{{notifInfo.type}}</td>
                                    <td>{{notifInfo.language}}</td>
                                    <td>{{notifInfo.set_up}}</td>
                                    <td>{{notifInfo.initiated}}</td>
                                    <td>{{notifInfo.completed}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="row">
          <div class="col-md-6 col-xl-12">
              <div class="card">
                  <div class="card-body row">
                      <div class="card-title center col-2">Notification Recipients</div>
                      <div class="col-10 " v-if="!showFullTable " >
                          <button @click="loadFullTable" class="btn btn-primary ">
                              See More
                          </button>
                      </div>

                      <div class="table-responsive">
                          <v-app>
                              <v-data-table
                                      v-if="showFullTable"
                                      :headers="tableHeaders"
                                      :items="notifRecipient"
                                      :search="filter"
                                      :items-per-page="perPage"
                                      :page="currentPage"
                                      :server-items-length="totalItems"
                                      :loading="showLoader"
                                      @update:page="onPageChange"
                                      @update:items-per-page="onItemsPerPageChange"
                              >

                                  <template v-slot:item.email="{ item }">
                                      <span v-if="!item.id">User not registered</span>
                                      <router-link v-else title="View Customer" :to="{ path: `/customers/profile/${item.id}`}">
                                          {{item.email}}
                                      </router-link>
                                  </template>


                                  <template v-slot:item.token="{ item }">
                                      <div>
                                          <span>{{formatToken(item.token)}}</span>
                                          <v-btn icon @click="copyToken(item.token)" title="Copy Token">
                                              <v-icon>mdi-content-copy</v-icon>
                                          </v-btn>
                                      </div>
                                  </template>

                                  <template v-slot:footer>
                                      <v-row v-if="showLoader_c1" class="mx-4">
                                          <v-col class="text-center">
                                              <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col >
                                              <v-pagination v-model="currentPage" :length="totalPages" :total-visible="6" @input="onPageChange" circle></v-pagination>
                                          </v-col>
                                      </v-row>
                                  </template>

                                  <template v-slot:top="{ pagination, options, updateOptions }">
                                      <v-row>
                                          <v-col md="6" sm="12">
                                              <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" :items-per-page-options="itemsPerPageOpts"/>
                                          </v-col>
                                          <v-col md="6" sm="12">
                                              <v-text-field v-model="filter" :append-icon="filter ? 'mdi-close' : 'mdi-magnify'" @click:append="clearSearch" label="Search" single-line hide-details outlined dense></v-text-field>
                                          </v-col>
                                      </v-row>
                                  </template>

                              </v-data-table>
                          </v-app>

                      </div>
                  </div>
              </div>
          </div>
      </div>


  </Layout>
</template>
